import { Menu, MenuItem, MenuTrigger, Popover, SubmenuTrigger, Key } from 'react-aria-components';

import { ReactNode } from 'react';

import RightArrow from '../../../assets/arrow-right.svg?react';

import styles from './DropDownMenu.module.css';

type MenuItemData = {
  value: string;
  text: string;
  icon?: ReactNode;
  isSubMenu?: boolean;
  subMenuItems?: Array<MenuItemData>;
};

type MenuProps = {
  trigger: ReactNode;
  items: Array<MenuItemData>;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onValueChange(value: Key): void;
  className?: string;
  conatinerPadding?: number;
};

function DropDownMenu({
  items = [],
  trigger,
  onValueChange,
  open,
  onOpenChange,
  className,
  conatinerPadding = 24,
}: MenuProps) {
  return (
    <MenuTrigger>
      {trigger}
      <Popover
        className={`${styles.MenuContainer} ${className}`}
        isOpen={open}
        onOpenChange={onOpenChange}
        containerPadding={conatinerPadding}
        placement="bottom right"
      >
        <Menu onAction={onValueChange}>
          {items.map((item: MenuItemData) =>
            item.isSubMenu ? (
              <SubmenuTrigger>
                <MenuItem className={styles.MenuItem} id={item.value} key={item.value}>
                  {item.icon && item.icon}
                  {item.text}
                  <RightArrow />
                </MenuItem>
                <Popover className={styles.MenuContainer}>
                  <Menu>
                    {item.subMenuItems &&
                      item.subMenuItems.map((subItem: MenuItemData) => (
                        <MenuItem className={styles.MenuItem} id={subItem.value} key={subItem.value}>
                          {subItem.icon && subItem.icon}
                          {subItem.text}
                        </MenuItem>
                      ))}
                  </Menu>
                </Popover>
              </SubmenuTrigger>
            ) : (
              <MenuItem className={styles.MenuItem} id={item.value} key={item.value}>
                {item.icon && item.icon}
                {item.text}
              </MenuItem>
            )
          )}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}

export default DropDownMenu;
