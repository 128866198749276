import * as RadixAvatar from '@radix-ui/react-avatar';

import styles from './Avatar.module.css';

type AvatarSize = 'Small' | 'Medium';

type AvatarProps = {
  title: string;
  url?: string;
  className?: string;
  size: AvatarSize;
};

const getInitials = (title: string) => {
  const words = title.split(' ');
  if (words.length === 1) {
    return words[0][0];
  }
  return words[0][0] + words[words.length - 1][0];
};

function Avatar({ title, url, className = '', size = 'Medium' }: AvatarProps) {
  return (
    <RadixAvatar.Root className={`${styles.AvatarRoot}  ${styles[`AvatarRoot${size}`]} ${className}`}>
      {url && <RadixAvatar.Image className={styles.AvatarImage} src={url} alt={title} />}
      <RadixAvatar.Fallback className={styles.AvatarFallback}>{getInitials(title)}</RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
}

export default Avatar;
