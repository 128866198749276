import { observer } from 'mobx-react-lite';

import Flex from '../../../common/Flex/Flex.tsx';
import DropDownMenu from '../../../common/DropDownMenu/DropDownMenu.tsx';
import Button from '../../../common/Button/Button.tsx';
import Text from '../../../common/Text/Text.tsx';
import Avatar from '../../../common/Avatar/Avatar.tsx';
import { useRegisterAppStore } from '../../../../providers/registration/RegisterAppStoreProvider.tsx';

import SignOut from '../../../../assets/sign-out.svg?react';
import WebexLogo from '../../../../assets/webex-color-logo.svg?react';

import style from './Header.module.css';

type Props = {
  showProfile?: boolean;
  showWebexLogo?: boolean;
};

const Header = observer(({ showProfile = true, showWebexLogo = false }: Props) => {
  const registerAppStore = useRegisterAppStore();
  const { avatarUrl, name } = registerAppStore;

  const handleSessionEnd = () => {
    registerAppStore.setSessionExpired(false);
    window.location.href = '/admin-login';
  };
  const handleAppHeaderClick = () => {
    registerAppStore.setRecordInsufficientPermission(false);
    registerAppStore.setInsufficientPermission(false);
  };

  const handleProfileDropdown = async (value: string) => {
    if (value === 'sign-out') {
      handleAppHeaderClick();
      const response = await registerAppStore.signOut();
      if (response.success) {
        handleSessionEnd();
      }
    }
  };
  return (
    <header className={style.Header}>
      <Flex justifyContent="space-between" width="100%">
        <Flex alignItems="center">
          <a href="/" className={style.SiteLogo} onClick={handleAppHeaderClick}>
            {showWebexLogo ? (
              <WebexLogo />
            ) : (
              <Text variant="Heading" size="Midsize" type="Regular">
                Webex LTI Registration Hub
              </Text>
            )}
          </a>
        </Flex>

        {!registerAppStore.isInSufficientPermission && showProfile && (
          <Flex>
            <DropDownMenu
              trigger={
                <Button
                  variant="Secondary"
                  size="Default"
                  ariaLabel="Profile"
                  type="button"
                  interactiveIcon
                  className={style.ProfileDropdownTrigger}
                  rightIcon={<Avatar url={avatarUrl} title={name} size="Medium" className={style.Profile} />}
                >
                  {name}
                </Button>
              }
              items={[{ value: 'sign-out', text: 'Sign out', icon: <SignOut /> }]}
              onValueChange={handleProfileDropdown}
            />
          </Flex>
        )}
      </Flex>
    </header>
  );
});

export default Header;
